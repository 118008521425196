import React, { FC, useMemo, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Sbs from './components/Sbs/Sbs'
import { ServiceType } from '../../core/models/service.model'
import { IFile } from '../../core/models/file.model'
import { BottomBar, Link, Wrapper } from './SandBox.styles'
import html2canvas from 'html2canvas'
import { onFileChange } from '../../helpers/helpers'
import { ButtonEl } from '../../components/ui/Button/Button.styles'
import Questionnaire from './components/Questionnaire/Questionnaire'
import HeatMap from './components/HeatMap/HeatMap'
import Drawer from './components/Drawer/Drawer'

const SandBox: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as IFile

  //Remove header row and empty rows
  const result = useMemo(() => {
    if (state?.result) {
      return state.result?.filter((_, index) => index % 2)
    }
  }, [state])

  const downloadTarget = useRef(null)

  const downloadImage = () => {
    if (downloadTarget.current) {
      html2canvas(downloadTarget.current, {
        logging: true,
        useCORS: true,
      }).then((canvas) => {
        const a = document.createElement('a')
        const filename = state.serviceType || 'result'
        a.href = canvas
          .toDataURL('image/jpeg')
          .replace('image/jpeg', 'image/octet-stream')
        a.download = filename.replace(/\.[^/.]+$/, '') + '.jpg'
        a.click()
      })
    }
  }

  const returnService = () => {
    switch (state?.serviceType) {
      case ServiceType.sbs:
        return <Sbs result={result} downloadTarget={downloadTarget} />
      case ServiceType.questionnaire:
        return <Questionnaire result={result} downloadTarget={downloadTarget} />
      case ServiceType.heatmap:
        return <HeatMap result={result} downloadTarget={downloadTarget} />
      case ServiceType.drawer:
        return <Drawer result={result} downloadTarget={downloadTarget} />
      default:
        return (
          <div className="text-center">
            <label>
              <input
                type="file"
                accept=".tsv"
                style={{ display: 'none' }}
                onChange={(e) => onFileChange(e, navigate)}
              />
              <ButtonEl as="span">Открыть файл с результатами</ButtonEl>
            </label>
          </div>
        )
    }
  }

  return (
    <Wrapper>
      <div className="container-lg">{returnService()}</div>
      {state && (
        <BottomBar>
          <label>
            <input
              type="file"
              accept=".tsv"
              style={{ display: 'none' }}
              onChange={(e) => onFileChange(e, navigate)}
            />
            <Link as="span">Открыть другой файл</Link>
          </label>
          <Link onClick={downloadImage}>Скачать картинку</Link>
        </BottomBar>
      )}
    </Wrapper>
  )
}

export default SandBox
