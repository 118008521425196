import React from 'react'
import { Wrapper, Logo, Nav } from './Header.styles'
import LOGO from '../../../assets/images/logo.svg'
import { Link } from 'react-router-dom'
import { routes } from '../../../core/config/routes'

const Header = () => {
  return (
    <Wrapper>
      <div className="container-md">
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/">
            <Logo src={LOGO} alt="Design Bakery logo" />
          </Link>
          <Nav>
            <Link to={routes.community}>Сообщество</Link>
            <Link to={routes.howItWorks}>Как это работает?</Link>
            <Link to={routes.sandbox}>Песочница</Link>
          </Nav>
        </div>
      </div>
    </Wrapper>
  )
}

export default Header
