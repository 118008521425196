import React, { FC, useEffect, useMemo, useState } from 'react'
import { IServicePage } from '../../../../core/models/service.model'
import Capture from '../../../../components/simple/Capture/Capture'
import { Img } from './Questionnaire.styles'
import ImagePreloader from '../../../../components/simple/ImagePreloader/ImagePreloader'
import { v4 as uuid } from 'uuid'
import Comment from '../../../../components/simple/Comment/Comment'
import LabeledCheckbox from '../../../../components/simple/LabeledCheckbox/LabeledCheckbox'

enum Tags {
  'A' = 'A',
  'B' = 'B',
  'C' = 'C',
}

interface IAnswer {
  value: string
  tag: Tags | null
  id: string
}

const Questionnaire: FC<IServicePage> = ({ result = [], downloadTarget }) => {
  const title = useMemo(() => result[1][0], [result])

  const [image, setImage] = useState<string>()
  const [answers, setAnswers] = useState<IAnswer[]>([])

  useEffect(() => {
    //answers
    setAnswers(
      result
        .map((row) => row[3])
        .map((value) => {
          return {
            value,
            id: uuid(),
            tag: null,
          }
        })
    )

    //fetch image
    const fetchImage = async () => {
      const res = await fetch(result[1][1])
      const imageBlob = await res.blob()
      const imageObjectUrl = URL.createObjectURL(imageBlob)
      setImage(imageObjectUrl)
    }

    fetchImage()
  }, [result])

  const handleTagChange = (id: string, tag: Tags) => {
    const newState = answers.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          tag: item.tag === tag ? null : tag,
        }
      }

      return item
    })

    setAnswers(newState)
  }

  const output = useMemo(
    () =>
      Object.keys(Tags)
        .map((tag) => {
          const res = answers.filter((item) => item.tag === tag)
          const title = res[0]?.value

          if (res.length) {
            return {
              title,
              count: res.length,
            }
          }
        })
        .filter((item) => item),
    [answers]
  )

  return (
    <>
      <div ref={downloadTarget}>
        <div className="lead-1 text-center">Вопрос</div>
        <h1 className="headline-3 text-center">«{title}»</h1>
        <div className="mb-4">
          <Capture>
            <div className="mb-4">
              {image ? (
                <Img src={image} alt="" />
              ) : (
                <ImagePreloader width="323px" height="323px">
                  Загрузка...
                </ImagePreloader>
              )}
            </div>
            {output?.map((item, index) => (
              <div className="mb-2" key={index}>
                <div className="d-flex justify-content-between">
                  <div className="pe-2">{item?.title}</div>
                  <div>
                    {item?.count} / {answers.length}
                  </div>
                </div>
                {index !== output.length - 1 && <hr />}
              </div>
            ))}
          </Capture>
        </div>
      </div>
      {answers.map(({ id, tag, value }) => (
        <div className="mb-2" key={id}>
          <div className="row gx-3">
            <div className="col">
              <Comment>{value}</Comment>
            </div>
            <div className="col-auto">
              <div className="row gx-2">
                {Object.keys(Tags).map((item) => (
                  <div className="col" key={item}>
                    <LabeledCheckbox
                      checked={item === tag}
                      text={item}
                      onClick={() => handleTagChange(id, item as Tags)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default Questionnaire
