import React, { FC, useEffect, useMemo, useState } from 'react'
import {
  Img,
  ImgLabel,
  Percentage,
  PercentageHeader,
  Percents,
  Progress,
  Result,
} from './Sbs.styles'
import Comment from '../../../../components/simple/Comment/Comment'
import LabeledCheckbox from '../../../../components/simple/LabeledCheckbox/LabeledCheckbox'
import { v4 as uuid } from 'uuid'
import { IServicePage } from '../../../../core/models/service.model'
import Capture from '../../../../components/simple/Capture/Capture'
import ImagePreloader from '../../../../components/simple/ImagePreloader/ImagePreloader'

interface IComment {
  value: string
  checked: boolean
  id: string
}

const Sbs: FC<IServicePage> = ({ result = [], downloadTarget }) => {
  const [image1, setImage1] = useState<string>()
  const [image2, setImage2] = useState<string>()

  const [aComments, setAComments] = useState<IComment[]>([])
  const [bComments, setBComments] = useState<IComment[]>([])

  useEffect(() => {
    //A comments
    const aComments = result
      .filter((row) => row[2] === 'A')
      .map((row) => {
        return {
          value: row[3],
          checked: true,
          id: uuid(),
        }
      })

    setAComments(aComments)

    //B comments
    const bComments = result
      .filter((row) => row[2] === 'B')
      .map((row) => {
        return {
          value: row[3],
          checked: true,
          id: uuid(),
        }
      })

    setBComments(bComments)

    //fetch image
    const fetchImage = async () => {
      const res = await Promise.all([fetch(result[1][0]), fetch(result[1][1])])
      const imageBlob1 = await res[0].blob()
      const imageBlob2 = await res[1].blob()
      const imageObjectUrl1 = URL.createObjectURL(imageBlob1)
      const imageObjectUrl2 = URL.createObjectURL(imageBlob2)
      setImage1(imageObjectUrl1)
      setImage2(imageObjectUrl2)
    }

    fetchImage()
  }, [result])

  const handleAChange = (id: string) => {
    const index = aComments.findIndex((item) => item.id === id)
    const newComments = [...aComments]
    newComments.splice(index, 1, {
      value: aComments[index].value,
      checked: !aComments[index].checked,
      id: aComments[index].id,
    })
    setAComments(newComments)
  }

  const handleBChange = (id: string) => {
    const index = bComments.findIndex((item) => item.id === id)
    const newComments = [...bComments]
    newComments.splice(index, 1, {
      value: bComments[index].value,
      checked: !bComments[index].checked,
      id: bComments[index].id,
    })
    setBComments(newComments)
  }

  const APercentage = useMemo(() => {
    if (aComments.length && bComments.length) {
      const aChecked = aComments.filter((item) => item.checked).length
      const bChecked = bComments.filter((item) => item.checked).length
      return Math.round((aChecked / (aChecked + bChecked)) * 100)
    } else {
      return 50
    }
  }, [aComments, bComments])

  const BPercentage = useMemo(() => {
    return 100 - APercentage
  }, [APercentage])

  return (
    <>
      <div className="text-center">
        <h1 className="headline-3">Какая картинка вам больше нравится?</h1>
      </div>
      <div className="mb-4">
        <Capture ref={downloadTarget}>
          <Result>
            <Img>
              {image1 ? (
                <img src={image1} alt="" />
              ) : (
                <ImagePreloader width="300px" height="300px">
                  Загрузка...
                </ImagePreloader>
              )}
              <ImgLabel>A</ImgLabel>
            </Img>
            <Percentage>
              <PercentageHeader>
                <Percents>{APercentage}%</Percents>
                <Percents>{BPercentage}%</Percents>
              </PercentageHeader>
              <Progress value={APercentage} />
            </Percentage>
            <Img>
              {image2 ? (
                <img src={image2} alt="" />
              ) : (
                <ImagePreloader width="300px" height="300px">
                  Загрузка...
                </ImagePreloader>
              )}
              <ImgLabel>B</ImgLabel>
            </Img>
          </Result>
        </Capture>
      </div>
      <div className="row row-cols-2">
        <div className="col">
          {aComments.map(({ id, value, checked }) => (
            <div className="mb-2" key={id}>
              <div className="row gx-3">
                <div className="col">
                  <Comment>{value}</Comment>
                </div>
                <div className="col-auto">
                  <LabeledCheckbox
                    checked={checked}
                    text="A"
                    onClick={() => handleAChange(id)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="col">
          {bComments.map(({ id, value, checked }) => (
            <div className="mb-2" key={id}>
              <div className="row gx-3">
                <div className="col">
                  <Comment>{value}</Comment>
                </div>
                <div className="col-auto">
                  <LabeledCheckbox
                    checked={checked}
                    text="B"
                    onClick={() => handleBChange(id)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Sbs
