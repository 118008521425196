import styled from 'styled-components'

const Wrapper = styled.label`
  display: block;
  background-color: #fff;
  height: 100%;
  border: 0.5px solid rgba(51, 51, 51, 0.1);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  overflow: hidden;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 10px 30px rgba(24, 160, 251, 0.15),
      0 2px 2px rgba(0, 0, 0, 0.05);
    transform: translateY(-5px);
  }
`

const ImageWrapper = styled.div`
  padding-bottom: calc(160 / 348 * 100%);
  position: relative;
  border-bottom: 1px solid var(--bs-gray-300);

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Desc = styled.div`
  padding: 16px 24px 24px;
`

const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  margin-top: 0;
  margin-bottom: 4px;
`

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-600);
`

export { Wrapper, ImageWrapper, Desc, Title, Description }
