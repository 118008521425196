import React, { FC, useEffect, useRef, useState } from 'react'
import { IHeatData, ISelected } from '../../../../core/models/heatData.model'
import HeatMap from 'heatmap-ts'
import { Image, Selection, SelectionArea, Wrapper } from './Canvas.styles'
import { v4 as uuid } from 'uuid'
import SelectedAreas from './SelectedAreas'
import { useHeatMapSettings } from './HeatMap'

interface IProps {
  img: string
  imgSize: [number, number]
  heatData: IHeatData[]
}

const Canvas: FC<IProps> = ({ img, imgSize, heatData }) => {
  const heatRef = useRef<HTMLDivElement>(null)
  const selectionAreaRef = useRef<HTMLDivElement>(null)
  const selectionRef = useRef<HTMLDivElement>(null)
  const [selected, setSelected] = useState<ISelected[]>([])
  const { imageFit, layers } = useHeatMapSettings()

  useEffect(() => {
    if (heatRef.current) {
      const heatMap = new HeatMap({
        container: heatRef.current,
        maxOpacity: 0.8,
        gradient: {
          0.4: 'blue',
          0.6: 'cyan',
          0.7: 'lime',
          0.8: 'yellow',
          1.0: 'red',
        },
        radius: 30,
        blur: 1,
        width: imgSize[0],
        height: imgSize[1],
      })

      heatMap.setData({
        max: 100,
        min: 1,
        data: heatData.map((item) => {
          return {
            x: imgSize[0] * item.left,
            y: imgSize[1] * item.top,
            value: 100,
          }
        }),
      })
    }
  }, [])

  const setSelectionStyles = (styles: React.CSSProperties) => {
    Object.assign(selectionRef.current?.style, styles)
  }

  let isMouseDown = false
  let top: number, left: number, bottom: number, right: number

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (layers.selection) {
      left =
        (e.pageX - heatRef.current!.offsetLeft) / heatRef.current!.offsetWidth
      top =
        (e.pageY - heatRef.current!.offsetTop) / heatRef.current!.offsetHeight
      setSelectionStyles({
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      })
      isMouseDown = true
    }
  }

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isMouseDown) {
      right =
        (e.pageX - heatRef.current!.offsetLeft) / heatRef.current!.offsetWidth
      bottom =
        (e.pageY - heatRef.current!.offsetTop) / heatRef.current!.offsetHeight

      const minX = Math.min(left, right)
      const maxX = Math.max(left, right)
      const minY = Math.min(top, bottom)
      const maxY = Math.max(top, bottom)

      setSelectionStyles({
        left: minX * 100 + '%',
        top: minY * 100 + '%',
        width: (maxX - minX) * 100 + '%',
        height: (maxY - minY) * 100 + '%',
      })
    }
  }

  const onMouseUp = () => {
    if (isMouseDown) {
      const minX = Math.min(left, right)
      const maxX = Math.max(left, right)
      const minY = Math.min(top, bottom)
      const maxY = Math.max(top, bottom)

      const filteredData = heatData.filter((item) => {
        const itemX = item.left
        const itemY = item.top
        return minX <= itemX && itemX <= maxX && minY <= itemY && itemY <= maxY
      })

      if (filteredData.length) {
        setSelected((prev) => {
          return [
            ...prev,
            {
              id: uuid(),
              top: minY * 100 + '%',
              left: minX * 100 + '%',
              width: (maxX - minX) * 100 + '%',
              height: (maxY - minY) * 100 + '%',
              amount: Math.floor((filteredData.length / heatData.length) * 100),
            },
          ]
        })
      }

      setSelectionStyles({
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      })

      isMouseDown = false
    }
  }

  const removeSelection = (id: string) => {
    const newSelected = [...selected]
    const removeIndex = newSelected.findIndex((item) => item.id === id)
    newSelected.splice(removeIndex, 1)
    setSelected(newSelected)
  }

  return (
    <SelectionArea
      ref={selectionAreaRef}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <Wrapper
        ref={heatRef}
        style={
          !imageFit
            ? {
                width: imgSize[0],
                height: imgSize[1],
              }
            : {}
        }
        heatMapVisible={layers.heatmap}
      >
        <Image
          imageFit={imageFit}
          src={img}
          alt=""
          style={!layers.image ? { opacity: 0 } : {}}
        />
        <Selection ref={selectionRef} />
        {layers.selection && (
          <SelectedAreas selected={selected} remove={removeSelection} />
        )}
      </Wrapper>
    </SelectionArea>
  )
}

export default Canvas
