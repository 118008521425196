import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: rgba(238, 238, 238, 1);
  padding: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 12px;
`

interface Props {
  children?: ReactNode
  className?: string | undefined
}

const Comment: FC<Props> = (props) => {
  return <Wrapper {...props} />
}

export default Comment
