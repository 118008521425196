import './styles/index.scss'
import Header from './components/layout/Header/Header'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home/Home'
import { routes } from './core/config/routes'
import SandBox from './pages/SandBox/SandBox'
import Main from './components/layout/Main/Main'

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path={routes.community}
            element={
              <div className="container-lg">
                Страница <b>Сообщество</b> находится в разработке
              </div>
            }
          />
          <Route
            path={routes.howItWorks}
            element={
              <div className="container-lg">
                Страница <b>Как это работает?</b> находится в разработке
              </div>
            }
          />
          <Route path={routes.sandbox} element={<SandBox />} />
          <Route path="*" element={<div>Страница не найдена</div>} />
        </Routes>
      </Main>
    </BrowserRouter>
  )
}

export default App
