import React, { ChangeEvent, FC } from 'react'
import { IService } from '../../../core/models/service.model'
import {
  Wrapper,
  ImageWrapper,
  Desc,
  Description,
  Title,
} from './ServiceCard.styles'

interface IProps {
  service: IService
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const ServiceCard: FC<IProps> = ({ service, onChange }) => {
  const { image, title, description } = service

  return (
    <Wrapper>
      <input
        type="file"
        onChange={(e) => onChange(e)}
        style={{ display: 'none' }}
        accept=".tsv"
      />
      <ImageWrapper>
        <img src={image} alt="" />
      </ImageWrapper>
      <Desc>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Desc>
    </Wrapper>
  )
}

export default ServiceCard
