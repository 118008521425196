import { ServiceType } from '../core/models/service.model'
import { ChangeEvent } from 'react'
import * as XLSX from 'xlsx'
import { routes } from '../core/config/routes'
import { NavigateFunction } from 'react-router-dom'

export const detectServiceType = (
  result: string[][]
): ServiceType | undefined => {
  if (
    result[0][0] === 'INPUT:image_left' &&
    result[0][1] === 'INPUT:image_right' &&
    result[0][2] === 'OUTPUT:result' &&
    result[0][3] === 'OUTPUT:comment'
  ) {
    return ServiceType.sbs
  }

  if (
    result[0][0] === 'INPUT:task' &&
    result[0][1] === 'INPUT:image' &&
    result[0][2] === 'INPUT:comment-question' &&
    result[0][3] === 'OUTPUT:comment'
  ) {
    return ServiceType.questionnaire
  }

  if (
    result[0][0] === 'INPUT:task' &&
    result[0][1] === 'OUTPUT:result' &&
    result[0][2] === 'GOLDEN:result' &&
    result[0][3] === 'HINT:text'
  ) {
    return ServiceType.drawer
  }

  if (
    result[0][0] === 'INPUT:link' &&
    result[0][1] === 'INPUT:task' &&
    result[0][2] === 'OUTPUT:result' &&
    result[0][3] === 'GOLDEN:result'
  ) {
    return ServiceType.heatmap
  }
}

export const onFileChange = (
  e: ChangeEvent<HTMLInputElement>,
  navigate: NavigateFunction
) => {
  e.preventDefault()
  if (e.target.files && e.target.files.length) {
    const file = e.target.files[0]

    const reader = new FileReader()

    reader.onload = (e) => {
      const data = e.target?.result
      const workbook = XLSX.read(data, { type: 'binary', codepage: 1251 })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      const result: string[][] = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
      })

      const serviceType = detectServiceType(result)

      navigate(routes.sandbox, {
        state: { serviceType, result },
      })
    }

    reader.readAsText(file)
  }
}
