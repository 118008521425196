import styled from 'styled-components'

export const ResultsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
`

export const ResultItem = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const ResultImage = styled.img`
  width: 120px;
  height: 120px;
  padding: 15px;
  border: 2px solid #eee;
  border-radius: 8px;
  margin-right: 20px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    border-color: #ccc;
  }
`

export const CaptureInner = styled.div`
  display: flex;
  gap: 24px;
`

export const CaptureItem = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 16px;

  img {
    width: 120px;
    height: 120px;
    padding: 15px;
    object-fit: contain;
    margin-bottom: 10px;
    border-width: 2px;
  }
`
