import React from 'react'
import { Section } from '../../components/layout/Section/Section'
import { v4 as uuidv4 } from 'uuid'
import Service1 from '../../assets/images/service-1.png'
import Service2 from '../../assets/images/service-2.png'
import Service3 from '../../assets/images/service-3.png'
import Service4 from '../../assets/images/service-4.png'
import { IService } from '../../core/models/service.model'
import ServiceCard from '../../components/simple/ServiceCard/ServiceCard'
import { onFileChange } from '../../helpers/helpers'
import { useNavigate } from 'react-router-dom'
import { ButtonEl } from '../../components/ui/Button/Button.styles'

const services: IService[] = [
  {
    id: uuidv4(),
    image: Service1,
    title: 'Side by side',
    description:
      'Сравнение двух картинок, люди выбирают один вариант и оставляют комментарий, почему его выбрали',
  },
  {
    id: uuidv4(),
    image: Service2,
    title: 'Комментарий к картинке',
    description:
      'Вы можете задать людям любой вопрос к картинке, чтобы собрать их мнения ',
  },
  {
    id: uuidv4(),
    image: Service3,
    title: 'Тепловая карта',
    description:
      'Люди нажимают в любую точку экрана по вашему вопросу, отслеживайте количества нажатий',
  },
  {
    id: uuidv4(),
    image: Service4,
    title: 'Нарисовать иконку',
    description:
      'Люди рисуют по заданному вопросу произвольную картинку, вы можете категоризировать результаты',
  },
]

const Home = () => {
  const navigate = useNavigate()

  return (
    <div className="container-lg" style={{ maxWidth: '744px' }}>
      <Section>
        <div className="text-center mb-6">
          <div className="mb-5">
            <h1 className="headline-1">Проверяй свои дизайн решения</h1>
            <div className="lead-1">
              Создавай шаблоны для исследований в{' '}
              <a
                href="https://toloka.yandex.ru/"
                target="_blank"
                rel="noreferrer"
              >
                Толоке
              </a>{' '}
              — анализируй результаты здесь
            </div>
          </div>
          <label>
            <input
              type="file"
              accept=".tsv"
              style={{ display: 'none' }}
              onChange={(e) => onFileChange(e, navigate)}
            />
            <ButtonEl as="span">Открыть файл с результатами</ButtonEl>
          </label>
        </div>
        <div className="row row-cols-2 gx-4 gy-4">
          {services.map((service) => (
            <div key={service.id} className="col">
              <ServiceCard
                service={service}
                onChange={(e) => onFileChange(e, navigate)}
              />
            </div>
          ))}
        </div>
      </Section>
    </div>
  )
}

export default Home
