import styled from 'styled-components'

const Wrapper = styled.div`
  margin-bottom: 64px;
`

const BottomBar = styled.div`
  box-shadow: 0px -4px 16px -4px rgba(0, 0, 0, 0.05);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 9;
  padding: 20px;
  text-align: center;

  & > * {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`

const Link = styled.button`
  background: none;
  padding: 0 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  border: none;
  cursor: pointer;
  font-family: inherit;
  color: var(--bs-gray-700);
  transition: 0.3s;

  &:hover {
    color: var(--bs-dark);
  }
`

export { BottomBar, Link, Wrapper }
