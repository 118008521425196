import styled from 'styled-components'

const Result = styled.div`
  display: flex;
  align-items: center;
`

const Img = styled.div`
  flex-grow: 1;
  flex-basis: 0;

  img {
    border-radius: 4px;
  }
`

const Percentage = styled.div`
  width: 208px;
  padding: 16px;
`

const PercentageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const Percents = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 4px;
`

const Progress = styled.div<{ value: number }>`
  background-color: #ccc;
  border-radius: 4px;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    height: 16px;
    background-color: #6c6c6c;
    width: ${(props) => props.value}%;
    transition: 0.3s;
  }
`

const ImgLabel = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-top: 16px;
`

export {
  Result,
  Img,
  Percentage,
  Percents,
  Progress,
  PercentageHeader,
  ImgLabel,
}
