import React, { FC, useMemo, useState } from 'react'
import { IServicePage } from '../../../../core/models/service.model'
import {
  CaptureInner,
  CaptureItem,
  ResultImage,
  ResultItem,
  ResultsWrapper,
} from './Drawer.styles'
import LabeledCheckbox from '../../../../components/simple/LabeledCheckbox/LabeledCheckbox'
import { v4 as uuid } from 'uuid'
import Capture from '../../../../components/simple/Capture/Capture'

type ImageTypes = 'a' | 'b' | 'c'

interface IImage {
  id: string
  url: string
  type?: ImageTypes
  isCover?: boolean
}

const Drawer: FC<IServicePage> = ({ result = [], downloadTarget }) => {
  const title = useMemo(() => result[0][0], [result])
  const [images, setImages] = useState<IImage[]>(
    result.map((item) => {
      return {
        id: uuid(),
        url: item[1],
        type: undefined,
      }
    })
  )
  const aImages = useMemo(
    () => images.filter((item) => item.type === 'a'),
    [images]
  )
  const bImages = useMemo(
    () => images.filter((item) => item.type === 'b'),
    [images]
  )
  const cImages = useMemo(
    () => images.filter((item) => item.type === 'c'),
    [images]
  )

  const aCoverUrl = useMemo(
    () => aImages.find((item) => item.isCover)?.url,
    [aImages]
  )
  const bCoverUrl = useMemo(
    () => bImages.find((item) => item.isCover)?.url,
    [bImages]
  )
  const cCoverUrl = useMemo(
    () => cImages.find((item) => item.isCover)?.url,
    [cImages]
  )

  const onCheckboxClick = (id: string, type: ImageTypes) => {
    const newImages = [...images]
    const imageIndex = images.findIndex((item) => item.id === id)
    const image = images[imageIndex]
    const newImage = {
      ...image,
      type: image?.type !== type ? type : undefined,
      isCover: false,
    }
    newImages.splice(imageIndex, 1, newImage)

    setImages(newImages)
  }

  const onImageClick = (id: string, type: ImageTypes) => {
    const newImages: IImage[] = [...images].map((item) => {
      return {
        id: item.id,
        url: item.url,
        type: item.type,
        isCover: item.type !== type ? item.isCover : false,
      }
    })
    const imageIndex = images.findIndex((item) => item.id === id)
    const image = images[imageIndex]
    const newImage = {
      ...image,
      isCover: true,
    }
    newImages.splice(imageIndex, 1, newImage)

    setImages(newImages)
  }

  return (
    <>
      <div className="lead-1 text-center">Задача</div>
      <h1 className="headline-3 text-center">«{title}»</h1>
      {!!(aImages.length || bImages.length || cImages.length) && (
        <Capture ref={downloadTarget} style={{ marginBottom: 24 }}>
          <CaptureInner>
            {!!aImages.length && (
              <CaptureItem>
                <div>
                  <img src={aCoverUrl || aImages[0].url} alt="" />
                </div>
                A - {aImages.length}
              </CaptureItem>
            )}
            {!!bImages.length && (
              <CaptureItem>
                <div>
                  <img src={bCoverUrl || bImages[0].url} alt="" />
                </div>
                B - {bImages.length}
              </CaptureItem>
            )}
            {!!cImages.length && (
              <CaptureItem>
                <div>
                  <img src={cCoverUrl || cImages[0].url} alt="" />
                </div>
                C - {cImages.length}
              </CaptureItem>
            )}
          </CaptureInner>
        </Capture>
      )}
      <ResultsWrapper>
        {images.map(({ id, url, type }) => (
          <ResultItem key={id}>
            <ResultImage
              src={url}
              alt=""
              onClick={() => type && onImageClick(id, type)}
            />
            <div style={{ display: 'flex', gap: 8 }}>
              <LabeledCheckbox
                text={'A'}
                checked={type === 'a'}
                onClick={() => onCheckboxClick(id, 'a')}
              />
              <LabeledCheckbox
                text={'B'}
                checked={type === 'b'}
                onClick={() => onCheckboxClick(id, 'b')}
              />
              <LabeledCheckbox
                text={'C'}
                checked={type === 'c'}
                onClick={() => onCheckboxClick(id, 'c')}
              />
            </div>
          </ResultItem>
        ))}
      </ResultsWrapper>
    </>
  )
}

export default Drawer
