import React, { FC } from 'react'
import { ISelected } from '../../../../core/models/heatData.model'
import { SelectionArea, Wrapper } from './SelectedAreas.styles'

interface Props {
  selected: ISelected[]
  remove: (id: string) => void
}

const SelectedAreas: FC<Props> = ({ selected, remove }) => {
  return (
    <Wrapper>
      {selected.map(({ amount, id, ...styles }) => (
        <SelectionArea style={styles} key={id} onClick={() => remove(id)}>
          {amount} %
        </SelectionArea>
      ))}
    </Wrapper>
  )
}

export default SelectedAreas
