import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { IServicePage } from '../../../../core/models/service.model'
import ImagePreloader from '../../../../components/simple/ImagePreloader/ImagePreloader'
import Canvas from './Canvas'
import { IHeatData } from '../../../../core/models/heatData.model'
import {
  Button,
  ButtonsPanel,
  Divided,
} from '../../../../components/simple/ButtonsPanel/ButtonsPanel'
import { HeatMapCapture } from './HeatMap.styles'

const SettingsContext = createContext<{
  imageFit?: boolean
  layers: { image: boolean; selection: boolean; heatmap: boolean }
}>({
  layers: {
    image: true,
    selection: true,
    heatmap: true,
  },
})

const HeatMap: FC<IServicePage> = ({ result = [], downloadTarget }) => {
  const title = useMemo(() => result[1][1], [result])
  const [imageUrl, setImageUrl] = useState<string>()
  const [imageSize, setImageSize] = useState<[number, number]>()
  const [heatData, setHeadData] = useState<IHeatData[]>()
  const [imageFit, setImageFit] = useState(true)
  const [layers, setLayers] = useState({
    image: true,
    selection: true,
    heatmap: true,
  })

  useEffect(() => {
    //parse heat data
    setHeadData(
      result.map(
        (item) => JSON.parse(('[' + item[2] + ']').replaceAll('\\', ''))[0]
      )
    )

    //fetch image
    const fetchImage = async () => {
      const res = await fetch(result[1][0])
      const imageBlob = await res.blob()
      const imageObjectUrl = URL.createObjectURL(imageBlob)
      const imgObj = new Image()
      imgObj.src = URL.createObjectURL(imageBlob)
      imgObj.onload = () => setImageSize([imgObj.width, imgObj.height])
      setImageUrl(imageObjectUrl)
    }

    fetchImage()
  }, [])

  return (
    <SettingsContext.Provider value={{ imageFit, layers }}>
      <div className="lead-1 text-center">Задача</div>
      <h1 className="headline-3 text-center">«{title}»</h1>
      <div className="text-center">
        <HeatMapCapture ref={downloadTarget}>
          {imageUrl && imageSize && heatData ? (
            <Canvas img={imageUrl} imgSize={imageSize} heatData={heatData} />
          ) : (
            <ImagePreloader width="323px" height="323px">
              Загрузка...
            </ImagePreloader>
          )}
        </HeatMapCapture>
      </div>
      <ButtonsPanel>
        <Button
          onClick={() => setImageFit((prev) => !prev)}
          className={`icon-${imageFit ? 'increase' : 'decrease'}-size`}
        />
        <Divided>
          <Button
            className="icon-selection"
            onClick={() =>
              setLayers((prevState) => {
                return { ...prevState, selection: !prevState.selection }
              })
            }
          />
          <Button
            className="icon-heatmaps"
            onClick={() =>
              setLayers((prevState) => {
                return { ...prevState, heatmap: !prevState.heatmap }
              })
            }
          />
          <Button
            className="icon-image"
            onClick={() =>
              setLayers((prevState) => {
                return { ...prevState, image: !prevState.image }
              })
            }
          />
        </Divided>
      </ButtonsPanel>
    </SettingsContext.Provider>
  )
}

export const useHeatMapSettings = () => useContext(SettingsContext)

export default HeatMap
