import styled from 'styled-components'

const Wrapper = styled.header`
  padding-top: 40px;
  padding-bottom: 40px;
`

const Logo = styled.img`
  width: 199px;
`

const Nav = styled.nav`
  display: flex;

  & > * {
    color: var(--bs-gray-700);
    text-decoration: none;
    transition: 0.3s;
    padding: 10px 0;
    font-weight: 500;

    &:hover {
      color: var(--bs-dark);
    }

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
`

export { Wrapper, Logo, Nav }
