import React, { FC } from 'react'
import styled, { css } from 'styled-components'

const Label = styled.div<{ $checked?: boolean }>`
  padding: 12px;
  min-width: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  background-color: #eeeeee;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #dadada;
  }

  ${(props) =>
    props.$checked &&
    css`
      background-color: #333333;
      color: #fff;

      &:hover {
        background-color: #555;
      }
    `}
`

interface Props {
  checked?: boolean
  text: string
  onClick?: () => void
}

const LabeledCheckbox: FC<Props> = ({ checked, text, onClick }) => {
  return (
    <Label $checked={checked} onClick={onClick}>
      {text}
    </Label>
  )
}

export default LabeledCheckbox
