import styled from 'styled-components'

const ImagePreloader = styled.div<{ width?: string; height?: string }>`
  width: ${({ width }) => width || '200px'};
  height: ${({ height }) => height || '200px'};
  background-color: #eee;
  animation: animation 1s infinite linear;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  @keyframes animation {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
`

export default ImagePreloader
