import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ heatMapVisible: boolean }>`
  position: relative;
  display: flex;
  border-radius: 4px;
  border: none;
  min-height: 0;

  canvas {
    ${(props) =>
      !props.heatMapVisible &&
      css`
        display: none;
      `}
    border-radius: 4px;
    width: 100%;
    height: 100%;
  }
`

export const Image = styled.img<{ imageFit?: boolean }>`
  border: none !important;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
  ${(props) =>
    props.imageFit &&
    css`
      max-height: calc(100vh - 400px);
    `}
`

export const Selection = styled.div`
  position: absolute;
  background-color: #87dbff;
  opacity: 50%;
  border: 1px dashed white;
  border-radius: 4px;
`

export const SelectionArea = styled.div`
  margin: -48px;
  padding: 48px;
  display: flex;
  min-height: 0;
`
