import React from 'react'
import styled from 'styled-components'

export const ButtonsPanel = styled.div`
  position: fixed;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Button = styled.button`
  font-size: 24px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08), 0 1px 0 rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background-color: #eaeaea;
  }
`

export const Divided = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08), 0 1px 0 rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  overflow: hidden;

  ${Button} {
    border: none;
    box-shadow: none;
    border-radius: 0;
  }
`
