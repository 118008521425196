import { IFile } from './file.model'
import { MutableRefObject } from 'react'

export interface IService {
  id?: string
  title: string
  description: string
  image: string
}

export interface IServicePage extends IFile {
  downloadTarget: MutableRefObject<null>
}

export enum ServiceType {
  heatmap = 'heatmap',
  drawer = 'drawer',
  sbs = 'sbs',
  questionnaire = 'questionnaire',
}
