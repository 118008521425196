import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

const Wrapper = styled.main`
  padding-bottom: 20px;
`

const Main: FC<{ children: ReactNode }> = (props) => {
  return <Wrapper {...props} />
}

export default Main
