import styled from 'styled-components'

const ButtonEl = styled.button`
  color: #fff;
  background-color: var(--primary);
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-radius: 24px;
  padding: 12px 20px;
  text-align: center;
  min-width: 120px;
  transition: 0.2s;
  cursor: pointer;
  font-family: inherit;
  display: inline-block;

  label {
    cursor: pointer;
  }

  &:hover {
    background-color: var(--primary-h);
  }
`

export { ButtonEl }
