import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`

export const SelectionArea = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px dashed #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 4px;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff,
    1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: rgba(255, 120, 120, 0.3);
  }

  &::selection {
    background-color: transparent;
    color: inherit;
  }
`
