import styled from 'styled-components'

const Capture = styled.div`
  border: 2px dashed rgba(51, 51, 51, 0.24);
  padding: 48px;
  border-radius: 16px;
  display: table;
  margin-left: auto;
  margin-right: auto;

  img {
    border: 1px solid #eee;
    border-radius: 4px;
    margin: 0 auto;
    display: block;
  }
`

export default Capture
